.nwBgImg{
    background-repeat: no-repeat;
    background-image: url("../../assets/img/rosa/Nature of work.svg");
    background-position: center;
    background-size: contain;
    }

.nw-page1{
  //  height: 88vh;
    //height: calc(var(--vh, 1vh) * 100);
    min-height: 88vh;
    height: auto;
    button{
        cursor: pointer;
        right:0;
        bottom: -9.25rem;
    }
}

.nw-page2{
    // overflow: scroll;
    min-height: 88vh;
    // button{
    //     right:0;
    //     bottom: -9.25rem;
    // }
}

.nw-page3{
    min-height: 88vh;
    button{
        cursor: pointer;
        right:0;
        bottom: -9.25rem;
    }}

.workTasks {
    margin: 1rem auto;
    align-items: center;
    padding: 1.5rem 1.7rem;
    width: 36.75rem;
    // height: 6rem;
    box-sizing: border-box;
    box-shadow: 2px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    button {
        cursor: pointer;
        border: none;
        background-color: transparent;
    }
    i {
        font-size: 2.75rem;
    }
}

.workTaskError {
    margin: 1rem auto;
    width: 36.75rem;
}
.selectHrs {
    border: 1px solid #F2D7DC;
    box-shadow: inset 0px 0px 4px rgb(193 193 193 / 8%);
    border-radius: 4px;
    height: 4.5rem;
    margin: 1rem 0;
    width: 20.875rem;
}
.selectMins {
    border: 1px solid #F2D7DC;
    box-shadow: inset 0px 0px 4px rgb(193 193 193 / 8%);
    border-radius: 4px;
    height: 4.5rem;
    margin: 1rem 0;
    width: 11.5rem;
}

.flex-label {
    display: flex;
}
.barChart{
    height: 31.625rem;
    canvas{
        background-color: #FFF6F8;
    }
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}