.wsaBgImg{
    background-repeat: no-repeat;
    background-image: url("../../assets/img/rosa/Workstation assessment.svg");
    background-position: bottom;
    background-size: cover;
    }
.checkbox{
align-items: center;
padding: 12px 24px;
height: 76px;
background: #FFFFFF;
box-shadow: 0px 1px 8px rgba(206, 206, 206, 0.25);
border-radius: 6px;
gap:2.625rem;
justify-content: space-between;
border: 2px solid #FFF;
}
label input[type=checkbox]{
    display:none;
}
.wa-radio{
    display:none;
}
.checked {
    border: 2px solid #5376AE;
}
.wa-page1{
    
}

.summary__group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.summaryFlex .summary__group:not(:first-child) {
    margin-top: 2rem;
}

.summary__group__content {
    border-radius: 1rem;
    height: 3.5rem;
    padding: 1.5rem;
    gap: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary__content {
    width: calc(50% - 10px);
    box-shadow: 0px 1px 8px rgba(206, 206, 206, 0.25);
    border-radius: 1rem;
    padding: 2.875rem 1.688rem;
    box-sizing: border-box;
}
.summary__content__items {
    margin-bottom: 1.5rem;
}

.summaryFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    gap: 1.5rem;
    margin: 0 auto;
}

.durationModal {
    margin-top:12px
}
.wa-page2,.wa-page6,.wa-page9 {
    min-height: 88vh;
}

// Tablets and mobile screens
@media screen and (min-width:1025px) {
    .responsiveDiv:has(.summaryFlex) {
        width: 30% !important;
    }
}
