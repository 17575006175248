
//Caliberated for 360 x 580 screen resolution
.section-container{
    height: calc(100vh - 14rem);
}
.section-content{
    padding-top: 2rem;
}
.section-header{
    padding-bottom: 1.5rem;
    width: 78%; //226px 
}
.nxtDiv {
    margin-top: 170px;
}
.notes{
    width: 27.375rem;
}