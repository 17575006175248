.biBgImg{
background-image: url('../../assets/img/rosa/Basic Information.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.ht-info{
    align-items: flex-start;
    margin-bottom: 1.25rem;
    input[type=text]{
        box-sizing: border-box;
        width:10.75rem;
    }
    input[type=number],input.textNumber[type=tel] {
        box-sizing: border-box;
        width:10.75rem;
    }
    select{
        box-sizing: border-box;
        width: 10.75rem;
        margin: 1rem 0;
    }
}
.nxt-btn1{
    right:4rem;
    bottom:9.75rem;
}

.wt-info{
    align-items: flex-start;
    input[type=text]{
        box-sizing: border-box;
        width:20.875rem
    }
    select{
        box-sizing: border-box;
        width: 10.75rem;
        margin: 1rem 0;
    }
    input.textNumber[type=text],input.textNumber[type=tel] {
        box-sizing: border-box;
        width: 10.75rem;
    }
}
.info__error {
    width:10.75rem;
}
.occupation__select {
    width: 34rem;
    margin: 1rem 0;
}

.height__select-type {
    margin: 1rem 0;
}

.lens-type {
    margin-right: 1rem;
    display: flex;
}

.basicPage1{
    height: calc(100vh - 17rem);
    button{
        cursor: pointer;
        right:0;
        bottom: -9.25rem;
    }}