.container{
    background-color: #FAEEE4;
    background-repeat: no-repeat;
}



.frame{    
    opacity: 0.8;
    border-radius: 16px;
    width:45.5%;
    margin:5% 5% 5% 50%;
    min-height:80vh;
    display: flex;    
}

.innerFrame{
    margin:14% 17% 14% 17%;
    width:100%;
}

.txtInput{
    border: 1px solid #EEC280;
    box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08) !important;
    border-radius: 4px !important;
    height: 48px !important;
    margin:0rem !important
}

.error-border{
    border:1px solid rgb(244, 67, 54) !important;
 }

.height80{
    height: 84vh;
}

.height70{
    height: 70vh;
    overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius:16px; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.width100{
    width:100% !important;
}

.width50{
    width:50% ;
}

.width96{
    width: 96%;
}


.width80{
    width: 80%;
}

.width90{
    width: 90%;
}

.width30{
    width:30%;
}

.width40{
    width:40%;
}

.width15{
    width:15%;
}
.width20{
    width:20%;
}
.width25{
    width:25%;
}

.width10 {
    width: 10%;
}

.centerAlign{
    text-align: center;
}

.leftPanelFrame{
    width:17%;
    min-width:17%;
}

.leftPanelFrameRB{
    width:4%;
    min-width:72px;
}

.rightPanelFrame{
    width: 100%;
    padding: 0 40px;
    padding-top: 20px;
}

.imgLoading{
    width:24px;
    height:24px;
    margin-left:16px;
}

.scrollY{    
    overflow-y: auto;
    overflow-x: hidden;
    height: 78vh;
}

.rightPanelFrameRB {
    width: 100%;
    margin-left: 40px;
    padding-top: 40px;
    margin-right: 40px;
}

.landing{
    display: flex;
}

.profileFrame{
    height:14vh;
    display: flex;    
    justify-content:flex-start;
    align-items: center;    
}
.profileFrameRB{
    height:14vh;
    display: flex;    
    justify-content:center;
    align-items: center;    
}

.landingHeader {
    display: flex;
    align-items: center;    
    justify-content: space-between;
    .searchInput {
        background-color: #FFFFFF;
        border-color: #133F9B;
    }
    .cta-btn {
        font-size: 14px;
        font-weight: bold;
    }
    .cta-btn__view-report {
        font-size: 14px;
        font-weight: bold;
        color: #133F9B
    }
}

.searchInput
{
    padding: 4px !important;

    width: 304px !important;
    height: 32px !important;

    background: rgba(19, 63, 155, 0.24);   /* Blue */

    border: 1px solid #133F9B;
    border-radius: 8px;
}

.dbTable{
    display: flex;
    div {
      font-size: 14px;
    }
    gap: 20px;
    padding: 0 20px;
    padding-bottom: 10px;
}

.dbTableNoMargin{
    display: flex;
    min-height: 48px;
    align-items: center;
    div:not(:first-child,:last-child) {
        font-family: 'Noto Sans Light 300';
    }
    .assessmentDate,.reportSentDate {
        color: #333333;
    }
}

.dbTableNoMargin {
    margin-left: 20px;
    margin-right: 20px;
    div {
        font-size: 14px;
    }
    gap: 20px;
}
.cta {
    text-transform: uppercase;
}

.whiteBox {
    margin-top:16px;
    border-radius:16px;
}

.flex{
    display: flex;    
}

.flexWrap{
    flex-wrap: wrap;
}

.justifySB{
    justify-content: space-between;
}

.flexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pointer{
    cursor:pointer;
}

.arrow{
    width:20px;
    height: auto;
    cursor:pointer;
}
.arrowCollapse {
    width:20px;
    height: 11px;
    cursor:pointer;
}

.backArrow{
    width: 14px;
    height: 24px;
    margin-right: 16px;
}
.linenBox{
    border-radius: 8px;
    border-radius: 8px;
    margin: 0px;
    margin-bottom: 8px;
    padding: 20px;
    position:relative;
    background-color: #faeee482; // 50% #FAEEE4;
}

.linenHeader{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.linenInput{
    width:86% !important;
    height:131px;
    border: 1px solid #133F9B !important;
    border-radius: 4px;
    margin:8px 0px 8px 40px;
}

.text-area-input {
    width: calc(100% - 14px);
    height:131px;
    border: 1px solid #133F9B !important;
    border-radius: 4px;
}

.drNote{
    width: calc(100% - 15px) !important;
    height:131px;
    border-radius: 8px;
    background-color: #F7F7F7;
    color: #133F9B;
    border: 0px;
    padding:8px;
}

.drRequestImageNote{
    width: calc(100% - 10%);
    height: 90px;
    margin-top: 8px;
}

.bold{
    font-weight: bold;
}

.linenTxt{
   display: flex;
   padding:0px 8px;
   margin-bottom: 8px;
   margin-left:32px;
}

.linenHeaderCircle{
    border-radius:50%;
    width:32px;
    height:32px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:8px;
    font-size: 15px;
    color: #133F9B;
    font-family: 'Noto Sans Medium 500';
}

.linenTabCircle{
    border-radius:50%;
    width:24px;
    height:24px;
    background-color: #FAEEE4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:8px;
    color: #133F9B;
}

.linenTabCircleWhite{
    border-radius:50%;
    width:24px;
    height:24px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:8px;
    color: #133F9B;
}



.linenImageCircle{
    border-radius:50%;
    width:32px;
    height:32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:8px;
    position: absolute;
}

.obsTxt{
    margin:8px 0px 24px 4px;
}

.posRelative{
    position: relative !important;
}

.linenInputCircle{
    width:20px;
    height:20px;
    margin-right:8px;   
    cursor:pointer;
}

.fadeOut{
    opacity:0;
    width:0;
    height:0;
    transition: width 0.25s 0.25s, height 0.25s 0.25s, opacity 0.25s;
    position: relative;
    div, button {
        display: none;
    }
}

.fadeIn{
    opacity:1;
    width:100%;
    height: auto;
    transition: width 0.25s, height 0.25s, opacity 0.25s 0.25s;
    position: relative;
}

.alignItemsCenter{
    align-items: center;
}

.imgWp__container {
    width: 260px;
    height: 260px;
    border-radius:16px;
    margin-bottom: 10px;
}

.imgWP {
    position: relative;
    z-index:99;
    margin-right:12px;
    margin-bottom:12px;
}

.imgWP img:not(.icEditImage) {
    width: 260px;
    height: 260px;
    border-radius:16px;
}

.goniometerEditIconContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.goniometerEditIcon{
    height: 20px;
    width: 23px;
    cursor: pointer;
}

.goniometerData{
    width: 100%;
    margin-top: 20px;
    margin-left: 20px;
}

.indentCircle {
    position: absolute;
    z-index:100;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.capitalize{
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

.dragImg {
    position: absolute;
    z-index:100;
    cursor: pointer;
}

.dragImg img {
    width: 40px;
    height: 40px;
}

.markBox{
    white-space: nowrap;
    padding: 23px 32px;
    border-radius: 16px;
    width: fit-content;
    color: #133F9B;
    background-color: #FAEEE4;
    line-height: 24px;
    margin-left: 16px;
}

.imgWS{
    border-radius:16px;
    margin-right:12px;
    border: 1px solid #EEC280;
    width: 150px;
    min-height: 140px;
    margin-bottom:16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    padding: 8px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    img {
        margin-bottom: 15px;
    }
}



.imgNWS40{
    border-radius:16px;
    margin-right:24px;
    border: 1px solid #EEC280;
    width:39%;
    margin-bottom:16px;
    display: flex;
    justify-content: flex-end;
    padding:16px 16px 8px 0px;
    align-items: center;
    div:last-child {
        margin-bottom: 1.5rem;
        align-self: center;
    }
}

.barChart{
    height: 31.625rem;
    canvas{
        background-color: #FFF6F8;
        margin-bottom: 10px;
    }
}

.doughnutChart {
    display: flex;
    justify-content: flex-end;
    transform: translateY(-15px);
}

.doughnutChart__content {
    width: 100px;
    height: 100px;
}

.imgNWS60{
    border-radius:16px;
    border: 1px solid #EEC280;
    width:50%;
    height:200px;
    margin-bottom:16px;
    padding:16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.breakColor{
    color:rgba(250, 169, 188, 1)
}

.phoneColor{
    color:rgba(238, 194, 128, 1)
}

.deviceColor{
   color:rgba(72, 177, 175, 1)
}

.sectionpdLeft{
    width: 100%;
}

.nightRider80{
    color: #323232;
    opacity: 0.8;
    margin-bottom: 8px;
}

.sectionpdRight{
    width: 49%;
}

.sectionpdRight img{
    max-height: 452px;
}

.painImg {
    max-height: 452px;
}

.whiteBoxBuilderLeft{
    width: 50%;
    margin-left:0px;
    margin-bottom:16px;
    border-radius:16px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 40px;
}

.whiteBoxBuilder{
    width: 50%;
    margin-left:6%;
    margin-bottom:16px;
    border-radius:16px;
    overflow: hidden;
    margin-top: 40px;
}


.dispNone{
    display: none;
}

.margin32px{
    margin:32px
}

.marg16px{
    margin-bottom:16px;
    margin-top:16px;
}

.marg8px{
    margin-bottom:8px;
    margin-top:8px;
}

.margleft2{
    margin-left: 2% !important;
}

.margLeft8{
    margin-left:8%;
}

.margLeft8px{
    margin-left:8px;
}

.rgBox{
    width: 90%;
    margin-left:4%;
    margin-top:16px;
    margin-bottom:16px;
}

.dbTableLine{
    height: 0px;
    border: 1px solid #DDDDDD;
}

.menu{
    height:8vh; 
    display: flex;    
    justify-content:space-between;
    align-items: center;   
}

.profileImgDiv{
    width:25%;
    padding:5%;
    display: flex;
    justify-content: flex-end;
}

.profileImg{
    border-radius: 50px;
    width: 48px;
    height: 48px;
    border: 1px solid #FAEEE4;
}

.pendingImg{
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scoreImg{
   border: 4px solid #DDDDDD;
   border-radius: 50px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn{   
    padding: 8px 48px;
    width: 160px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    background: #133F9B;
    border:1px solid #133F9B;
    border-radius: 8px;
}

.pointer{
    cursor: pointer;
}

.relative{
    position: relative !important;
}

.sendReport{
    position: absolute;
    width: 160px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    background: #133F9B;
    border:1px solid #133F9B;
    border-radius: 8px;
    bottom:7vh;
    right:7%;
    z-index:102;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px !important;
}

.gridWS{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 0fr);
    padding:16px 0px;
    margin-top:48px
}

.imgWSRep{    
    margin-right:48px;
    height:200px;
    margin-bottom:8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:column;
}

.imgWSRep img {
    height: 60px;
}

.sendPopup{
    background-color: #133F9B;
    height:24vh;
    width:24%;
    position: absolute;
    border-radius: 6px;
    left:38%;
    top:34vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading{
    background-color: #FFFFFF;
    height:100vh;
    width:100%;
    position: fixed;    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.loadingText {
    color:#133F9B;
    font-weight: bold;
    margin-left: 20px;
}

.errorText {
    color:red;
    font-weight: bold;
    margin-left: 20px;
}

.btnReport{   
    padding: 8px 48px;
    width: 160px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    background: #FFFFFF;
    border:1px solid #133F9B;
    border-radius: 8px;
    margin-right: 8px;
}

.btnGenerate{
    background: #FAEEE4;
    color:#133F9B;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.viewReUploadImageSectionContainer {
    width: 40%;
    position: absolute;
    height: 90vh;
    margin: 2% 31%;
    z-index: 101;
    overflow-x: hidden;
    overflow-y: auto;
}

.requestReUploadSectionpara {
    margin: 8px 0 16px 0;
}

.requestReUploadSection {
    width: 80%;
    margin: 0 10% 0 13%;
}

.btn_reUploadRequest {
    font-size: 14px;
    font-weight: bold;
    color: #133F9B;
    width: 220px;
    height: 40px;
    padding: 10px 0px;
}

.btn_reUploadRequestContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 260px;
}

.text_reUploadRequestContainer {
    justify-content: flex-start;
    margin-bottom: 0px;
    height: 30px;
}

.sendReUploadReminder {
    cursor: pointer;
    margin: 10px 0px;
    padding: 0px;
    background: none;
    border: none;
}

.text_reUploadRequest {
    margin-right: 20px;
}

.btn_reUploadRequestContainerWithDiagnosis {
    margin-top: 20px;
}

.reUploadRequestedTextContainer {
    align-items: center;
    justify-content: space-between;
}

.clockRotateLeft {
    height: 20px;
    width: 20px;
}

.reUploadSectionImageViewContainer {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-between;
}

.imageContainerSection {
    display: flex;
    align-items: flex-start;
    margin: 20px 0px;
}

.checkbox_reUploadImages {
    height: 20px;
    width: 20px;
    margin: 0 10px 0 0;
}

.reUploadSectionImageView {
    height: 200px;
    width: 200px;
    border-radius: 8px;
}

.send-request-button-container {
    display: flex;
    justify-content: space-between;
    margin: 24px 50px 0 0;
}

.selectImageError, .sendingMailLoading {
    margin-left: 0;
    padding: 0;
}

.send-request-btn {
    padding: 8px 16px;
}

.paraGoniometerReadings{
    color: #333333;
    opacity: 80%;
    margin-left: 20px;
}

.paraGoniometerReadingName{
    color: #333333;
    opacity: 80%;
}

.paraGoniometerReadingBody, .paraGoniometerReadingValue{
    color: #000000;
}

.goniometerValueInput{
    border: 1px solid #133F9B;
    box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08);
    border-radius: 4px;
    height: 2.5rem;
    margin: 1rem 0;
}

.btnDB{
    padding: 8px 12px;
    width: 110px;    
    cursor:pointer;
    text-decoration: none;
    background: #133F9B;
    border:1px solid #133F9B;
    border-radius: 8px;
    align-self: flex-end;
    font-size: 14px;
    font-weight: bold;
}

.priority{
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
}

.opacity64{
    opacity: 0.64;
}

.reportHeader,.severity-modal__header,.select-reviewer-modal__header {
    background-color: #FAEEE4;
    height:60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #133F9B;
}


.reportBg{
    position: absolute !important;
    opacity: 0.8;
    width:100%;
    height: 100vh; 
    z-index: 100;
}



.report{
    width:48%;
    position: absolute;
    height: 90vh;
    margin: 2% 31%;
    z-index: 101;
    overflow-x: hidden;
    overflow-y: auto;
}

.close{
    cursor:pointer;
    position: absolute;
    right:16px;
    top:16px;
}

.reportBody{
    padding:16px 64px 16px 64px;
    .chartPercentageDetail {
        margin-bottom: 50px;
        align-self: center;
    }
}

.reportFooter{
    width: 100%;
    display: flex;
    position: relative;
    background-color: #133F9B;
    height: 9px;
}

.mainBtn {
    position: absolute;
    width: 160px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    background: #133F9B;
    border:1px solid #133F9B;
    border-radius: 8px;
    bottom:7vh;
    right:7%;
    z-index:102;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px !important;
}
.mainBtn__disabled {
    cursor: none;
    background: gray;
}


.flexCol {
    display: flex;
    flex-direction: column;
}


.popupModal {
    width: 48%;
    position: absolute;
    height: 90vh;
    margin: 2% 31%;
    z-index: 101;
    overflow-x: hidden;
    overflow-y: auto;
}
.popupModal__body {
    padding: 40px 64px 0px 64px;
}
.search__input {
    width: calc(100% - 6px) !important;
}

.options__container {
    max-height: 450px;
    overflow-y: scroll;
    margin: 0;
    margin-top: 15px;
    padding: 10px 0;
}

.options__row {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin: 15px 0;
}

.options__row--top {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   margin: 15px 0;
}

.options__input {
    margin: 10px !important;
    width: 30px;
    height: 30px;
    padding: 5px;
}

.options__container__checkbox {
    margin: 0;
}
.options__row__checkbox {
   display: flex;
   flex-direction: row;
   align-items: center;
}
.options__input__checkbox {
    margin: 10px !important;
    width: 20px;
    height: 30px;
    padding: 5px;
}
.options__input__checkbox--sm {
    margin: 0px 5px 5px 0 !important;
    width: 15px;
    height: 20px;
    padding: 5px;
}

.text-overflow {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.overFlowDisable {
    overflow: unset;
}

.workstationPicturesContainer {
    margin: 0 0 25px 0;
}

.workstation-snapshot {
    gap: 2rem;
}
.workstation-snapshot__summary__group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
}
.workstation-snapshot__summary__header {
    font-size: 14px;
    color: #333333;
    opacity: 80%;
}
.workstation-snapshot__summary_image_content {
    font-size: 10px;
    color: #333333;
}

.overview-severity {
    position: relative;
    display: flex;
    align-items: flex-start;
    .icAbsoluteRightContainer {
        background-color: transparent;
        border-color: transparent;
        position: relative;
        top: unset;
        right: unset;
        height: 15px;
        margin-left: 5px;
        .icEditImage {
            width: 12px;
            height: 12px;
        }
    }
}

.editIconContainer{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor:pointer;
    background: #f1f1f1;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f1f1f1;
}

.overview-severity__header {
    color: #333333;
    opacity: 80%;
}
.overview-severity__content {
    font-family: 'Noto Sans Regular 400';
}

.severity-modal__score__description-input {
    border: 1px solid #133F9B;
    height: 100px;
    width: 100%;
    border-radius: 4px;
    margin-top: 0px;
}

.severity-modal .severity-modal__score__score-input {
    text-align: center;
    width: 80px;
    margin-left: 15px;
    height: 40px;
    margin-top: 0px;
    border: 1px solid #133F9B;
}
.severity-modal__score__btn,.select-reviewer-modal__btn {
    position: relative;
    z-index: 0;
    top: 0px;
    right:0px;
    float:right;
    align-self: end;
    margin: 60px 0;
    height: 40px;
    width: 190px;
    margin-bottom: 40px;
    text-transform: uppercase;
}
.popupModal.severity-modal,.select-reviewer-modal {
    height: auto;
    left:0;
    overflow: unset;
}

.painDiscomfortSummaryHeader {
    font-size: 14px;
    color: #333333;
    opacity: 80%;
}

.painDiscomfortSummaryProvider {
    border-radius: 4px;
    margin: 10px 0 30px 0;
}
.tab__container {
    border-bottom: 1px solid #DDDDDD;
    margin-left: 28px;
    margin-right: 28px;
}

.tab__header {
    width:50%;
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    padding-top: 10px;
    cursor: pointer;
}

.tab__header--active {
    color:red;
    border-bottom: 3px solid #133F9B;;
    font-size: 16px;
    color: #133F9B;
    font-weight: 600;
    .tab__header__suffix {
        background-color: #133F9B;
        color: white;
        border-color: #133F9B;
    }
}

.tab__content {
    overflow-y: auto;
    height: 74vh;
    margin: 40px 0;
    padding-left: 28px;
    padding-right: 20px; // 8px for scroll bar width
    z-index: 0;
}

.tab__content__preview {
    font-size: 12px;
    white-space: pre-line;
    font-family: 'Noto Sans Light 300';
}

.tab__header__suffix {
    border-radius:50%;
    border: 1px solid #333333;
    width: 18px;
    height: 18px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    font-size: 10px;
}

.ewa__table__name {
    font-family: 'Noto Sans Regular 400';
}

.pre-wrap--break-line {
  white-space: pre-line;
}

.report-contact-info {
    background-color: #F1F1F1;
    padding: 40px;
    gap: 40px;
    text-align: center;
}

.report-contact-info__contact__detail {
    margin-top: 20px;
    .email::before{
        content: url('../../../assets/img/rosa/admin/email.png');
        margin-right: 5px;
    }
    .phone::before{
        content: url('../../../assets/img/rosa/admin/phone.png');
        margin-right: 5px;
    }
}

.sub-text {
    color: #333333;
    opacity: 80%;
}

.additional-box {
    // margin: 40px;
    background: white;
}

.text {
    font-weight: 400;
    font-size: 14px;
}

.link {
    font-weight: 600;
    font-size: 14px;
    color: #133F9B;
    text-decoration: none;
}

.h15 {
    height: 15px;
  }

.wt600 {
    font-weight: 600 !important;
}

@media (max-width:600px)  { 
    .popupModal {
        width: 80vw;
        margin: 2% 10%;
    }
    .popupModal__body {
        padding: 4px 16px 4px 16px;
    }
    .search__input {
        width: calc(100% - 6px) !important;
    }
}