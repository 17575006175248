.login-view {
  position: relative;
  padding: 6rem 3rem;
  box-sizing: border-box;

  .wave-img {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 2.5rem;
    right: 0;
    z-index: 0;
  }

  .form-box {
    background: transparent;
    z-index: 1;
    position: relative;
  }

  .questionBox {
    box-shadow: none;
    padding: 0;
    margin-top: 16px;

    input {
      border-color: #EEC280;
      width: 95%;
      box-sizing: border-box;
      padding: 0 6px;
      margin: 1rem 0 0.5rem 0;
      &:focus {
        outline: none;
        box-shadow: 1px 1px 1px -3px #eec280 inset;
      }
    }

    &.error {
      input {
        border-color: transparent;
        box-shadow: 0px 0px 4px rgb(244, 67, 54);
      }
      .error-text{
        color: rgb(244, 67, 54);
      }
    }
  }

  .forget-pass {
    margin-top: 12px
  }

  .nxt-btn {
    background-color: #133F9B;
    margin-bottom: 32px;
  }

  .create-link {
    margin-left: 8px;
    text-decoration: underline;
    margin-top:-5px;
  }
}
.signup-view, .login-view{
  .nxt-btn:disabled{
    background-color: #BDBDBD;
  }
}
.signup-view {
  padding: 4rem 3rem;

  .wave-img {
    top: -5.6rem;
    right: 0;
    bottom: unset;
  }

  .questionBox {
    margin-top: 16px;

    &.req .label:after {
      content: '*';
    }
  }

  .nxt-btn {
    margin-bottom: 0;
    &:disabled{
      background-color: #BDBDBD;
    }
  }

  .radio {
    margin-top: 8px;
    .radio-circle {
      cursor: pointer;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #EEC280;
      margin-right: 8px;
      padding: 2px;
      background-color: transparent;
      overflow: hidden;

      .radio-checked {
        border-radius: 50%;
        background-color: #EEC280;
      }
      &.error{
        border-color: transparent;
        box-shadow: 0px 0px 4px rgb(244, 67, 54);
      }
    }
  }
  .check-box{
    margin-top: 8px;
    cursor: pointer;
    .tick{
      display: none;
    }
    .box{
      width: 15px;
      height: 15px;
      // border-radius: 4px;
      // border: 3px solid #133F9B;
      // box-sizing: border-box;
      margin-right: 8px;
    }
    &.choosen {
      .tick{
        display: block;
        width: 9px;
        height: 9px;
        object-fit: cover;
      }
    }
    &.error{
      color: rgb(244, 67, 54);
      .box{
        border-color: rgb(244, 67, 54);
      }
    }
  }
}

.splash-view {
  position: relative;

  .wave-img {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0;
  }

  .text-block {
    top: 24%;
    position: relative;
    color: rgb(19, 63, 155);
  }
}
.forget-pass{
  position: relative;
  .next-blc{
    position: absolute;
    bottom: 5vh;
    right: 0;
  }
}