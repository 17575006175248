.pdBgImg{
  background-repeat: no-repeat;
  background-image: url("../../assets/img/rosa/pain _ discomfort.svg");
  background-position: bottom;
  background-size: cover;
  }
.pdBgImg2{
    background-repeat: no-repeat;
    background-image: url("../../assets/img/rosa/pd-bg2.png");
    background-position: 14.25rem center;
    background-position: center;
  }
.imgDiv{
    width: 200px;
    margin: 0 auto;
    position: relative;
}

input[type='range'] {
    width: 34rem;
    margin-top: 2.5rem;
    height: 0.5rem;
  }
  
.pd-page1 {
    min-height: 88vh;
    background-color: #D8E7E3;
}

  .pd-page2, .pd-page3, .pd-page4, .pd-page5, .pd-page6, .pd-page7{
    min-height: 88vh;
    background-color: #D8E7E3;
    button{
      cursor: pointer;
      right:0;
      bottom: -9.25rem;
    }
  }

  .neck{
    vertical-align: middle;
    position: absolute;
    top:63px;
    left: calc(50% + 1px);
    transform: translate(-50%, -50%);
  }
  .shoulder{
    vertical-align: middle;
    position: absolute;
    top:88px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .midBack{
    position: absolute;
    top :142px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lowerBack{
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .leftElbow{
    position: absolute;
    top: 155px;
    left: 42px;
    transform: translate(-50%, -50%);
  }
  .rightElbow{
    position: absolute;
    top: 155px;
    right: 42px;
    transform: translate(50%, -50%);
  }
  .rightWrist{
    position: absolute;
    top: 214px;
    right: 25px;
    transform: translate(50%, -50%);
  }
  .leftWrist{
    position: absolute;
    top: 214px;
    left: 25px;
    transform: translate(-50%, -50%);
  }
  .leftThigh{
    position: absolute;
    top: 280px;
    left: 75px;
    transform: translate(-50%, -50%);
  }
  .rightThigh{
    position: absolute;
    top: 280px;
    right: 75px;
    transform: translate(50%, -50%);
  }
  .leftKnee{
    position: absolute;
    top: 350px;
    left: 75px;
    transform: translate(-50%, -50%);
  }
  .rightKnee{
    position: absolute;
    top: 350px;
    right: 75px;
    transform: translate(50%, -50%);
  }
  .leftAnkle{
    position: absolute;
    top: 428px;
    left: 80px;
    transform: translate(-50%, -50%);
  }
  .rightAnkle{
    position: absolute;
    top: 428px;
    right: 80px;
    transform: translate(50%, -50%);
  }