.footDiv{
    bottom: 35px;
}
.viewSummary-btn{
    margin-top: 5.125rem;
}
.homeImg{
    background-repeat: no-repeat;
background-image: url('../../assets/img/rosa/Introduction.svg');
background-size: cover;
}

