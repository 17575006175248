.tableOfContent{
    padding-top: 24px;
    div{
        margin-top: 2.25rem;
    } 
}
.homeImg{
    background-repeat: no-repeat;
background-image: url('../../assets/img/rosa/Introduction.svg');
background-size: cover;
}

