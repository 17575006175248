.profile-view {
  .change-pass{
    text-align: left;
    margin-top: 12px;
  }
  .form{
    z-index: 2;
  }
  .logout-btn {
    padding: 8px 28px;
    width: 124px;
    border-radius: 35px;
    border: none;
  }
}