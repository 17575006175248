body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
}
html{
  font-size: 16px;
  // overflow: hidden;
}
html, body {
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



// Font families
@font-face {
  font-family: 'Source Serif Pro SemiBold 600';
  src: url('./assets/fonts/SourceSerifPro-SemiBold.ttf');
}

@font-face {
  font-family: 'Source Serif Pro Regular 400';
  src: url('./assets/fonts/SourceSerifPro-Regular.ttf');
}
@font-face {
  font-family: 'Noto Sans Light 300';
  src: url('./assets/fonts/NotoSans-Light.ttf');
}
@font-face {
  font-family: 'Noto Sans Regular 400';
  src: url('./assets/fonts/NotoSans-Regular.ttf');
}
@font-face {
  font-family: 'Noto Sans Medium 500';
  src: url('./assets/fonts/NotoSans-Medium.ttf');
}
@font-face {
  font-family: 'Noto Sans Bold 700';
  src: url('./assets/fonts/NotoSans-Bold.ttf');
}
@font-face {
  font-family: 'Roboto Regular 400';
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

.responsiveDiv{
  width: 100%;
  margin:unset;
  position: relative;
}

//Font heirarchy
.h1{
  font-family: 'Source Serif Pro Regular 400';
  font-size: 4rem;
}
.h2{
  font-family: 'Source Serif Pro SemiBold 600';
  font-size: 3rem; 
}
.h3{
  font-family: 'Source Serif Pro Regular 400';
  font-size: 2.25rem;
}
.h4{
  font-family: 'Noto Sans Regular 400';
  font-size: 14px;
}
.h5{
  font-family: 'Noto Sans Light 300';
  font-size: 1.75rem;
}
.h6{
  font-family: 'Noto Sans Bold 700';
  font-size: 1.75rem;
}
.h7{
  font-family: 'Noto Sans Light 300';
  font-size: 1.5rem;
}
.h8{
  font-family: 'Roboto Regular 400';
  font-size: 1.5rem;
}
.h9{
  font-family: 'Noto Sans Light 300';
  font-size: 1.25rem;
}
.h10{
  font-family: 'Noto Sans Regular 400';
  font-size: 1.25rem;
}
.h11{
  font-family: 'Noto Sans Medium 500';
  font-size: 1.25rem;
}
.h12{
  font-family: 'Noto Sans Regular 400';
  font-size: 1rem;
}
.h13{
  font-family: 'Noto Sans Medium 500';
  font-size: 1rem;
}
.h14{  
  font-family: 'Noto Sans Medium 600';
  font-size: 1rem;
}
.h15{
  font-family: 'Source Serif Pro Regular 400';
  font-size: 1.8rem;
}

.font12 {
  font-family: 'Noto Sans Light 300';
  font-size: 12px;
}


//Background colors
.white-bg{
  background-color: #FFFFFF;
}
.cranberry-bg{
  background-color: #DC5D72;
}
.vanillaIce-bg{
  background-color: #F2D7DC;
}
.fountainBlue-bg{
  background-color: #48B1AF;
}
.swansDown-bg{
  background-color: #DAEDEC;
}
.shipCove-bg{
  background-color: #6685B7;
}
.botticelli-bg{
  background-color: #DCE3EE;
}
.patina-bg{
  background-color: #52907E;
}
.mystic-bg{
  background-color: #D8E7E3;
}
.capePalliser-bg{
  background-color: #9D6647;
}
.bizarre-bg{
  background-color: #ECDCD3;
}
.linen-bg{
  background-color: #FAEEE4;
}
.toreaBay-bg{
  background-color: #133F9B;
}
.cornflowerBlue-bg{
  background-color: #F8F8F8;
}
.vinRouge-bg{
  background-color: #9B3E4E;
}
.tacao-bg{
  background-color: #EEC280;
}
.alto-bg {
  background-color:#DDDDDD
}
.mineShaft-bg {
  background-color:#333333
}
.paradiso-bg{
  background-color:#328280;
}
.spice-bg{
  background-color: #68422D;
}
.pineGlade{
background-color: #C3C97D;
}
.lavenderPink-bg{
  background-color: #FAA9BC
 }
 .william-bg {
  background-color:#3A6C5E;
}
.monteCarlo-bg{
  background-color: #8FD2D1;
}
.bilobaFlower{
  background-color: #CD98ED;
}
.wedgeWood-bg{
background-color: #5376AE;
}
.eastBay-bg{
  background-color: #425C86;
}
.geraldine-bg{
  background-color: #F6988B;
}
//border
.tacao-border{
  color:#EEC280
}

.toreaBay-border{
  border: 1px solid #133F9B;
}

// Font colors
.eastBay{
  color: #425C86;
}
.tacao{
  color:#EEC280
}
.lavenderPink{
 color: #FAA9BC
}
.spice{
  color: #68422D;
}
.william {
  color:#3A6C5E;
}
.white{
  color: #FFFFFF;
}
.fountainBlue{
  color: #48B1AF;
}
.toreaBay{
  color: #133F9B;
}
.mineShaft{
  color: #333333;
}
.linen{
  color:#FAEEE4
}
.alto {
  color:#DDDDDD
}
.vinRouge{
  color: #9B3E4E;
}
.grey{
  color: #323232;
}
.crail{
  color: #BE4040;
}
.paradiso{
  color:#328280;
}
.txtLeft{
text-align: left;
}
.txtRight{
  text-align: right;
}
.txtCenter{
  text-align: center;
}
.nxtLine{
  margin-bottom: 1rem;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.sticky-top {
  position: sticky;
  top: 0;
}
.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.col{
  display: flex;
  flex-direction: column;
}
.f-rest{
  flex: 1;
}
.space-between {
  justify-content: space-between;
}
.v-ctr {
  justify-content: center;
}
.v-end{
  align-items: flex-end;
}
.h-end{
  justify-items: flex-end;
}
.flex-wrap{
  flex-wrap: wrap;
}
.noScroll{
  overflow: hidden;
}
.pageScroll{
  overflow: scroll;
}
.container{
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.main{
  //height: 100vh;
  // height: 52.25rem;
  // min-height: 100vh;
  z-index: 0;
  padding: 3rem 4rem;
  // position: relative;
}
.header{
  z-index: 0;
  padding: 1rem 4rem 0 4rem;
  position: relative;
}
.progressBar{
  padding-top: 4.5rem;
  padding-bottom: 3rem;
}
// img{
//   // width: 100%;
// }
.bg-img{
  width: 220vw;
  // z-index: 99;
  position: absolute;
  /* transform: rotate(15.21deg); */
  bottom: -21.25rem;
  left: -21.25rem;
  // bottom: -170px;
  // left: -170px;
}
.nxt-btn-div{
  margin-top: 30px;
}
.nxt-btn{
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 2.75rem;
  border: none;
  // position: absolute;
  i{
      font-size: 2.75rem;
  }
}
.questionBox{
  box-shadow: 0px 1px 8px rgba(206, 206, 206, 0.25);
  border-radius: 8px;
  padding: 2.25rem 1.5rem 2.25rem 1.5rem;
}
.questionBoxLens{
  box-shadow: 0px 1px 8px rgba(206, 206, 206, 0.25);
  border-radius: 8px;
  padding: 2.25rem 1.5rem 2.25rem 1.5rem;
  .collapse-box{
    margin-bottom: 3rem;
  }
}
input[type=text], input[type=email], input[type=password], input[type=tel], input[type=date]{
  border: 1px solid #F2D7DC;
  box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08);
  border-radius: 4px;
  height: 4.5rem;
  width:33.375rem;
  margin: 1rem 0;
}

input[type=number]{
  border: 1px solid #F2D7DC;
  box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08);
  border-radius: 4px;
  height: 4.5rem;
  // width: 100%;
  margin: 1rem 0;
}

input.textNumber[type=text] {
  border: 1px solid #F2D7DC;
  box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08);
  border-radius: 4px;
  height: 4.5rem;
  margin: 1rem 0;
}

input[type=radio]{
  margin-left: 0;
}
// input:disabled{
//   background-color: white;
// }
select {
  border: 1px solid #F2D7DC;
  box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08);
  border-radius: 4px;
  height: 4.5rem;
  opacity: 1;
}
.select2 .select2__container {
  border: 1px solid #133F9B;
  box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08);
  border-color: #133F9B;
  border-radius: 4px;
  opacity: 1;
  box-sizing: content-box;
  .select2__value-container,.select2__placeholder,.select2__single-value,.select2__single-value {
    color: #333333;
  }
  .select2__input {
    height: 40px;
  }
}

.select2 .select2__container:hover {
  border: 1px solid #133F9B;
  box-shadow: inset 0px 0px 4px rgba(193, 193, 193, 0.08);
  border-color: #133F9B;
  border-radius: 4px;
  opacity: 1;
}

.select2 .select2__container {
  .select2__indicators,.select2__indicator-separator,.select2__indicator {
    background-color: transparent;
  }
  .select2__indicators,.select2__indicator-separator,.select2__indicator:hover {
    color: #133F9B;
    background-color: transparent;
  }
}
.width100{
  width: 100%;
}
.width95 {
  width: 95%;
}
.minHeight100{
min-height: 100vh;
}
.height100{
  height: 100%;
}
.height100vh{
  height: 100vh;
}
.height80{
  height: 80vh;
}
.modal-container{
  display: grid;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
 // position: absolute;
 position: fixed;
  top: 0;
  align-items: center;
  z-index: 100000;
}
.modal{
  box-sizing: border-box;
  margin: 0 auto;
  width: 38.25rem;
  // height: 25.5rem;
  padding: 2rem;
  z-index: 99;
  // position: absolute;
  // left:50%;
  // top:50%;
  // transform: translate(0%, -50%);
  box-shadow: 0px 0px 23px 1px rgba(19, 63, 155, 0.1);
  border-radius: 12px;
  .modal-title{
    padding-bottom: 1.5rem;
  }
  .modal-body{
    padding-bottom: 2.25rem;
  }
  .modal-button{
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .left-btn{
    padding-left: 10.75rem;
  }
 
}

.back-btn-header {
  font-size: 2.5rem !important;
  padding-right: 2rem;
  border: none;
  background-color: transparent;
  padding-left: 0;
}

.save-btn{
  width: 19.625rem;
  height: 5.5rem;
  border-radius: 4.375rem;
  border: none;
  margin-top: 1.5rem;
}
.questionBox{
  .ewa-date{
    width: 95%;
    border: 1px solid #EEC280;
    box-sizing: border-box;
    padding: 0 6px;
    margin: 1rem 0 0.5rem 0;
    box-shadow: inset 0px 0px 4px rgb(193 193 193 / 8%);
    border-radius: 4px;
    height: 4.5rem;
    background-color: #FFFFFF;
    input{
      border: 0;
      height: 100%;
      margin: 0 !important;
    }
    .MuiInputBase-root {
      height: 100%;
      font: inherit
    }
    fieldset{
      display: none;
    }
  }
  .date-pick{
    position: absolute;
    left: calc(90% - 15px); // input width 95% => icon will have left 90% and minus padding we want
    border: none;
    margin-top: 0.5rem; // input outside margin-top 1 rem, margin-bottom: 0.5rem. => use 0.5rem to make top-bottom equal
  }
}
.MuiPickersPopper-root, .MuiPaper-root {
  @extend .h7;
  *{
    font: inherit !important;
  }
}

.error {
  .error-text {
    color: rgb(244, 67, 54);
  }
}
.w95percent {
  width: 95%;
}

.row:has(input[type=radio]) {
  gap: 20px;
}

.font-bold {
  font-weight: 750;
}

.report__user__info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.report__user__info__modal {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.report__user__info__items {
  margin-top: 15px;
  flex: 1 1 50%;
}
.report__user__info__items--empty {
  margin-top: 0px;
  flex: 1 1 50%;
  height: 0;
}
.report__user__info__items__content {
  color: #000000;
  font-family: 'Noto Sans Regular 400';
  font-size: 1.25rem;
  text-transform: capitalize;
}

.report__user__nature-of-work__items {
  margin-top: 15px;
  flex: 1 1 50%;
}
.report__user__nature-of-work__items--empty {
  margin-top: 0px;
  flex: 1 1 50%;
  height: 0;
}
.report__user__nature-of-work__items__content {
  color: #000000;
  font-family: 'Noto Sans Regular 400';
  font-size: 1.25rem;
  text-transform: capitalize;
}

.icAbsoluteRightContainer {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor:pointer;
    background: #f1f1f1;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f1f1f1;
}
.icAbsoluteRightContainer:hover {
    border: 1px solid #133F9B;
}

.icAbsoluteLeftContainer {
  right: unset;
  left: 5px;
}

.reuploadRequestedContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 30px;
  width: 150px;
  border: none;
  border-radius: 4px;
  left: 10px;
  top: 0px;
  background: #000000;
  opacity: 0.4;
  color: #ffffff;
  cursor:default;
}

.icEditImage {
    width:20px;
    height: auto;
    cursor:pointer;
}

.icEditImage:hover {
    width:20px;
    height: auto;
    cursor:pointer;
}

.d-none {
  display: none;
}

.collapse__container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.collapse__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 27px;
    align-items: center;
    z-index: 1;
}

.collapse__divider {
  width: calc(100% - 55px);
}
.mt-15 {
  margin-top: 15px;
}


.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.justify-content-between {
    justify-content: space-between;
}

.items-center {
  align-items: center;
}
.text-center {
  text-align: center;
}

.flex1 {
  flex: 1
}
.flex2 {
  flex: 2
}
.flex3 {
  flex: 3
}

.w-100 {
  width : 100%;
}
.grid-3-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  align-content: center;
}
.grid-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  align-content: center;
}
.grid-row-gap10 {
  grid-row-gap: 10px;
}

.painDiscomfortSummary { 
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0 30px 0;
}

.painDiscomfortSummary__table{
  width: 100%;
}

.painDiscomfortSummary__table, .painDiscomfortSummary__table td  {
  padding: 10px;
  border-collapse: collapse;
  border : 1px solid #E4E4E4;
}
.painDiscomfortSummary__level {
  color: #292929;
  font-family: 'Noto Sans Regular 400';
  font-size: 12px;
  font-weight: 400;
}
.logout { 
  color: #FAEEE4;
  font-family: 'Noto Sans Regular 400';
  font-size: 10px;
  align-items: center;
  margin-top: 5px;
}
.logout__icon {
  width: 10px;
  height: 10px;
  margin-right: 3px;
  padding-left: 2px;
}
.min-h-80vh {
    min-height:80vh;
}

.ic44 {
  width: 44px;
  height: 44px;
}

.self-center {
  align-self: center;
}
// Tablets and mobile screens
@media  (max-width: 1050px) {
  html {
    font-size: 8px !important;
  }
  .back-btn-header {
    font-size: 4rem !important;
    padding-right: 1rem;
    border: none;
    background-color: transparent;
    padding-left: 0;
  }
  .header {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main:has(.responsiveDiv) {
    padding: 3rem 15px;
  }

  .responsiveDiv.login-view {
    padding: 6rem 15px;
    .questionBox input {
      width: 100%;
    }
  }

  .row:has(input[type=radio]) {
    column-gap: 40px;
  }
  .responsiveDiv .workTasks {
    width: 100%;
  }

  .responsiveDiv .workTaskError {
    width: 100%;
  }
  
  .responsiveDiv .workTasks input[type=range] {
    width: 100%;
  }
  .report__user__info {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.hrBlank {
  border: none;
}
.uppercase {
  text-transform: uppercase;
}

.alert {
    background-color: #133F9B;
    height:24vh;
    width:24%;
    position: absolute;
    border-radius: 6px;
    left:38%;
    top:34vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// Medium desktop screens
@media screen and (min-width:1025px) {
  html {
    font-size: 12px ;
  }
  .responsiveDiv {
    width: 40% !important;
    margin: auto !important;
    position: relative;
  }
}

@media (max-width:600px) {
  .questionBox .date-pick {
    position: absolute;
    left: calc(90% - 25px); // input width 95% => icon will have left 90% and minus padding we want
    border: none;
    margin-top: 0.5rem; // input outside margin-top 1 rem, margin-bottom: 0.5rem. => use 0.5rem to make top-bottom equal
  }

  .modal {
    width: 95%
  }
}