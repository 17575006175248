.wsiBgImg{
    background-repeat: no-repeat;
    background-image: url("../../assets/img/rosa/Workstation images.svg");
    background-position: bottom;
    background-size: contain;
    }
.sampleImg{
width: 37rem;
height: 33.75rem;
margin: 1.5rem auto;
background: #9D6647;
border-radius: 8px;
img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

}
.uploadImg{
    width: 37rem;
    height: 33.75rem;
    margin: 1.5rem auto;
   // background: #9D6647;
    border-radius: 8px;
    // opacity: 0.2; 
    .capturedImg{
        border-radius: 8px;
    height: 270px;
    width: 296px;
    object-fit: cover;
    align-self: center;
    }
   video
   { 
    height: 270px;
   // position: fixed;
    object-fit: cover;
    border-radius: 8px;
    transform: scaleX(-1);}
}
.ws-page1, .ws-page2,.ws-page3,.ws-page4,.ws-page5,.ws-page6,.ws-page7,.ws-page8,.ws-page9{
    position: relative;
    height: calc(100vh - 15rem);
button{
    cursor: pointer;
    right:0px;
bottom: -100px;
}
}
.finalImgContainer {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    height: 258px;
    .col{
        gap: 2rem
    }
}
.reUploadFinalImgContainer {
    height: auto;
}
.reUploadResponsiveDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.finalImg {
    width:17.5rem;
    height:119px;

    img{
        width: 100%;
        height: 119px;
        object-fit: cover;
        object-position: top;
        border-radius: 8px;
    }
}

.camera-btn{
    width: 12.5rem;
    height: 44px;
    //width: 6.5rem;
//height: 6.5rem;
border: none;
border-radius: 6px;
margin: 24px 0;
}

.upload-btn{
    width: 5.5rem;
height: 5.5rem;
border: none;
border-radius: 5.5rem;
margin: 24px 1.5rem;
}

.cameraBar{
    gap:1.5rem;
    margin-top:2rem;
}

.uploadImg {
    display: flex;
    flex-direction: column;
    video {
        align-self: center;
    }
}